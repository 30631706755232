import React from "react"
import { css } from "@emotion/core"
import { colors, border, sizes } from "../styles/constants"

function ContentBox({ children, className }) {
    return (
        <div
            css={css`
                border-radius: ${border.radius};
                position: relative;
                background: white;
                padding: ${sizes.smallScale.m};
            `}
            className={className}
        >
            <div
                css={css`
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: ${colors.primary};
                    height: ${sizes.smallScale.xs};
                    border-top-left-radius: ${border.radius};
                    border-top-right-radius: ${border.radius};
                `}
            />
            {children}
        </div>
    )
}

export default ContentBox
