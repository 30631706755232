import React, { useContext } from "react"
import { css } from "@emotion/core"
import { graphql } from "gatsby"
import ContentBox from "../components/ContentBox"
import {
  desktopMediaQuery,
  sizes,
  fontSizes,
  backgroundPatterns,
  colors,
  shadow,
  containerQuery,
} from "../styles/constants"
import { differenceInCalendarDays } from "date-fns"
import { LanguageCtx, defaultLang } from "../context"
import { method } from "../components/Booking/PaymentInformation"

function ThanksMsg({ location, data }) {
  const { lang } = useContext(LanguageCtx)
  const isDefaultLang = lang === defaultLang
  const {
    title,
    confirmationText,
    conformationInfo,
    priceSupportText,
    cashPayment,
    transferPayment,
    szepCardPayment,
    bookingSummary,
  } = data.markdownRemark.frontmatter
  const {
    basePrice,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  const { state = {} } = location
  const {
    bookedRange,
    city,
    country,
    door,
    email,
    familyname,
    firstname,
    numberOfGuests,
    paymentMethod,
    phone,
    postcode,
    street,
    from,
    to,
    finalPrice,
  } = state

  const numberOfNights = differenceInCalendarDays(to, from)

  const paymentTexts = {
    [method.CASH]: cashPayment,
    [method.TRANSFER]: transferPayment,
    [method.SZEP_CARD]: szepCardPayment,
  }

  return (
    <div css={backgroundPatterns.strongGrayish}>
      <section css={styles.section}>
        <div className="container" css={styles.container}>
          <ContentBox css={styles.thanksContainer}>
            <h1>{title}</h1>
            <p css={styles.confirmText}>{confirmationText}</p>
            <p>{conformationInfo}</p>
          </ContentBox>
          <ContentBox css={styles.summaryContainer}>
            <h1 css={styles.title}>{bookingSummary}</h1>
            <ul css={styles.booking}>
              <li css={styles.valueWithSupport}>
                <div>{finalPrice} Ft</div>
              </li>
              <li css={styles.valueWithSupport}>
                <div>{bookedRange}</div>
                <div css={styles.supportText}>
                  {numberOfNights}
                  {` ${isDefaultLang ? "éj" : "nights"}`}
                </div>
              </li>
              <li>
                {numberOfGuests}
                {` ${isDefaultLang ? "vendég" : "guests"}`}
              </li>
            </ul>
            <ul css={[styles.columnView, styles.address]}>
              <li>
                {isDefaultLang
                  ? `${familyname} ${firstname}`
                  : `${firstname} ${familyname}`}
              </li>
              <li>{country}</li>
              <li>{`${postcode}, ${city}`}</li>
              <li>{street}</li>
              {door && <li>{door}</li>}
            </ul>
            <ul css={styles.contact}>
              <li>{phone}</li>
              <li>{email}</li>
            </ul>
            <span css={styles.payment}>{paymentTexts[paymentMethod]}</span>
          </ContentBox>
        </div>
      </section>
    </div>
  )
}

export default ThanksMsg

export const query = graphql`
  query ThanksMsg($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        confirmationText
        conformationInfo
        priceSupportText
        cashPayment
        cardPayment
        szepCardPayment
        transferPayment
        bookingSummary
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "data" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              basePrice
            }
          }
        }
      }
    }
  }
`

const styles = {
  section: css`
    ${desktopMediaQuery} {
      padding: ${sizes.largeScale.xs} ${sizes.smallScale.l}
        ${sizes.smallScale.l} ${sizes.smallScale.l};
    }
  `,
  container: css`
        /* ${desktopMediaQuery} {
        } */
        @media (max-width: 66rem) {       
            max-width: 800px;
        }
    `,
  thanksContainer: css`
    background: ${colors.primary};
    padding: ${sizes.smallScale.l};
    padding-top: ${sizes.smallScale.xxxl};
    & h1,
    & p {
      color: ${colors.primary200};
    }
    & h1 {
      font-size: ${fontSizes.xxLarge};
      padding-bottom: ${sizes.smallScale.m};
    }
    & p {
      width: 100%;
      color: ${colors.primary200};
    }
    ${containerQuery.stage1} {
      padding: ${sizes.smallScale.l};

      & p {
        width: 50%;
      }
      width: ${sizes.largeScale.xl};
      transform: translate(-1%, 11%);
    }
    ${containerQuery.stage2} {
      transform: translate(-9%, 11%);
      & p {
        width: 80%;
      }
    }
    ${containerQuery.stage3} {
      transform: translate(9%, 11%);
    }
    ${containerQuery.stage4} {
      transform: translate(22%, 10%);
    }
  `,
  confirmText: css`
    font-weight: bold;
  `,
  summaryContainer: css`
        display: grid;
        grid-template-areas:
            "title title"
            "booking booking"
            "address address"
            "contact contact"
            "payment payment";
        grid-row-gap: ${sizes.smallScale.l};
        padding: ${sizes.smallScale.l};
        /* & ul {
            font-size: ${fontSizes.medium};
        } */
        & li {
            padding-bottom: ${sizes.smallScale.s};
        }
        ${containerQuery.stage1} {
            & ul {
            font-size: ${fontSizes.medium};
        }
            ${shadow.base};
            width: ${sizes.largeScale.xl};

            transform: translate(49%, -38%);
        }
        ${containerQuery.stage2} {
            width: ${sizes.largeScale.xxl};

            grid-template-areas:
                "title title"
                "booking booking"
                "address contact"
                "payment payment";
            transform: translate(57%, -38%);
        }
        ${containerQuery.stage3} {
            transform: translate(70%, -38%);
        }
        ${containerQuery.stage4} {
            transform: translate(84%,-38%);
        }
    `,
  title: css`
    grid-area: title;
    font-size: ${fontSizes.title.h4};
    font-family: "Montserrat", sans-serif;
    ${desktopMediaQuery} {
      font-size: ${fontSizes.title.default};
    }
  `,
  valueWithSupport: css``,
  supportText: css`
    color: ${colors.darkGray};
    font-style: italic;
    font-size: ${fontSizes.default};
  `,
  booking: css`
    grid-area: booking;
  `,
  address: css`
    grid-area: address;
  `,
  contact: css`
    grid-area: contact;
  `,
  payment: css`
    grid-area: payment;
    ${desktopMediaQuery} {
      font-size: ${fontSizes.medium};
    }
  `,
}
